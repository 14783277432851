
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Image from 'mui-image'
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import ListingReport from "./ListingReport";
import PlayIcon from '@mui/icons-material/PlayCircle';

import _ from "lodash";

// Top Marketing
// Strong Negoitaors
// Experiaced Team
// Stagging & Photography
// Resources
// Contact Your Realtor

class FeaturedListings extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      pictureHeight: "100%",
      pictureWidth: "60%",

      showListingReport: false,

      showCommunities: false,
      showLuxery: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showOlderListings: false,
      showForeclosureListings: false,
      
      featured: [],

      subService: "Featured Listings",
            
    }
  }

 toggleListingReport = () => {
    this.setState({
      showListingReport: !this.state.showListingReport,
    });
  };

  showListingReport = (listingid) => {
    this.setState({
      showListingReport: !this.state.showListingReport,
      listingId: listingid
    });
  }

  callback = (response) => {
    if(response && response.data.featured){
      this.setState({
        featured: response.data.featured
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.showFeaturedListings !== this.props.showFeaturedListings) {
        this.setState({
          showCommunities: false,
          showLuxery: false,
          showWaterfront: false,
          showNewConstruction: false,  
          showRecent:false,
          subService:"Featured Listings"  
        })
        this.props.getFeaturedListings(this.props.parentPage, "mylistings", null, this.callback)
      }
  }


  toggleFeaturedListings = () => {
    this.setState({
      FeaturedListings: !this.state.FeaturedListings,
      subService:"Featured Listings"
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleSubServices = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: "Featured Listings"
    })
  }


  toggleCommunities = () => {
    this.setState({
      showCommunities: !this.state.showCommunities,
      showLuxery: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: !this.state.showCommunities ? "Local Villages" : "Featured Listings"
    })
  }

  toggleMillion = () => {
    this.setState({
      showCommunities: false,
      showLuxery: !this.state.showLuxery,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: false,
      subService: !this.state.showLuxery ? "Luxery Listings" : "Featured Listings"
    })
  }

  toggleRecent = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showRecent: !this.state.showRecent,
      showWaterfront: false,
      showNewConstruction: false,
      subService: !this.state.showRecent ? "Developments" : "Featured Listings"
    })
  }


  toggleWaterfront = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showWaterfront: !this.state.showWaterfront,
      showNewConstruction: false,
      showRecent: false,
      subService: !this.state.showWaterfront ? "Waterfront" : "Featured Listings"
    })
  }

  toggleNewConstruction = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showWaterfront: false,
      showRecent: false,
      showNewConstruction: !this.state.showNewConstruction,
      subService: !this.state.showNewConstruction ? "New Construction" : "Featured Listings"
    })
  }
  

  togglOlderListings = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showOlderListings: !this.state.showOlderListings,
      subService: !this.state.showOlderListings ? "Older Listings" : "Featured Listings"
    })
  }

  togglForeclosureListings = () => {
    this.setState({
      showCommunities: false,
      showLuxery: false,
      showWaterfront: false,
      showNewConstruction: false,
      showRecent: false,
      showCoreclosureListings: !this.state.showForeclosureListings,
      subService: !this.state.showForeclosureListings ? "Foreclosures" : "Featured Listings"
    })
  }

  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Let Me Guide You Through The Process",
      summary:
      "I will work to ensure that your journey is as smooth and stress-free as possible. Whether you are a first-time buyer or an experienced home owner, I am here to support you every step of the way.",
      download: true,
    }

    return (
      <React.Fragment>
            <Grid
              item
              sx={{
                width: "100%",
                padding: {
                  xs:"10px",
                  sm:"10px",
                  md:"10px",
                  lg:"20px",
                  xl:"20px"
                },
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
                
              }}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
            >
              <CommonTile
                animationType={null}
                animationTime={null}
                tagPicture={null}
                tagTxt={tile.tag}
                tagTxtColor={"black"}
                tagTxtSize={["12px", "12px", "16px"]}
                tagTxtType={"bold"}
                tagTxtAlign={"center"}
                tagVerticalAlign={"flex-start"}
                tagHorizontalAlign={"flex-end"}
                tagBgColor={"#73be73"}
                tagBorder={"1px solid black"}
                tagBorderRadius={"15px"}
                topPadding={"5px"}
                topPicture={tile.image}
                topPictureAlign={"100% 100%"}
                topPictureSize={"contain"}
                topTxt={null}
                topTxtColor={"black"}
                topTxtSize={["12px", "12px", "12px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topBgColor={"rgba(255,255,255,1)"}
                topHeight={["225px", "60%", "60%"]}
                topHorizontalAlign={"center"}
                topVerticalAlign={"flex-end"}
                topGraphType={null}
                topGraphData={null}
                mdlPadding={"5px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={tile.name}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1.2rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"left"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"1px solid black"}
                mdlHeight={["50px", "10%", "10%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"5px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={tile.summary}
                btmTxtColor={this.props.theme.textColor}
                btmTxtSize={["1rem", ".8rem", "1rem"]}
                btmTxtType={"bold"}
                btmTxtAlign={"left"}
                btmTxtLineHeight={"1.25"}
                btmHorizontalAlign={"flex-start"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmBorder={"1px solid black"}
                btmHeight={["150px", "30%", "30%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
      </React.Fragment>
    );
  };

  downloadResources = (filename) => {
    // Make a request to the server to download the file
    fetch('/' + filename)
      .then(response => {
        // Create a new blob object from the response
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
  
        // Create a new link element
        const link = document.createElement('a');
  
        // Set the link's href attribute to the URL
        link.href = url;
  
        // Set the link's download attribute to the desired file name
        link.download = filename;
  
        // Click the link to initiate the download
        link.click();
  
        // Clean up the URL object
        window.URL.revokeObjectURL(url);
      });
  }


  createSection = (section, title, intro, outro) => {
    return (
      <Grid
        container
        ref={section}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: "rgba(255,255,255,.75)",
          height: "100%",
          width: "100%",
          borderBottom: "0px solid #333333",
        }}
      >
        
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "80%",
              lg: "75%",
              md: "75%",
              sm: "75%",
            },
            paddingTop: "10px",
            fontSize: "2rem",
          }}
          xs={12}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              
              height:"100%",
              width: "100%",
            }}
          >
            {this.state.featured.length > 0 ? this.getFeaturedListingsTile(this.state.featured[0]) : this.getContactTile()}
            {this.state.featured.length > 0 ? this.getFeaturedListingsTile(this.state.featured[1]) : this.getContactTile()}
            {this.state.featured.length > 0 ? this.getFeaturedListingsTile(this.state.featured[2]) : this.getContactTile()}

          </Grid>
        </Grid>
        {this.getIdxTile()}
        <Grid
          item
          sx={{
            width: "100%",
            height: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
            },
            padding: "5px",
            fontSize: "2rem",
          }}
          xs={12}
        >
           <Footer theme={this.props.theme}  />
        </Grid>
      </Grid>
    );
  };

  getIdxTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            background: "rgba(255,255,255,0)",
            padding: "0px",
            height: {
              xl: "10%",
              lg: "15%",
              md: "15%",
              sm: "50%",
            },
            border:"0px solid black"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"0px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "0px", "0px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["0px", "0%", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["0px", "0px", "0px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255,255,255,0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={2}
                          sm={2}
                          md={1}
                        >
                            <img alt="image" src="howardhanna/idx-lg.png" height={25} width={40}/>&nbsp;&nbsp;
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:".55rem",
                              sm:".55rem",
                              md:".55rem",
                              lg:".55rem",
                              xl:".55rem"
                            },
                            height:"100%",
                            width:"100%",   
                            fontWeight:"normal",
                          }}
                          xs={10}
                        >                          
                          The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX) 
                          Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
                          with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
                          may not be used for any purpose other than to identify prospective properties that consumers may be 
                          interested in purchasing. Listing Data last updated at [timestamp]. 
                          <br/>
                          Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
                          properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real 
                          Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
                          totally harmless. © 2024 CNYIS, UNYREIS, WNYREIS. All rights reserved.
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={0}
                          sm={0}
                          md={1}
                        >
                        </Grid>
                        </Grid>
            }
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"flex-end"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255,255,255,0)"}
            btmBorder={"0px solid black"}
            btmHeight={["425px", "100%", "100%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid black"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,0)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  


getFeaturedListingsTile = (tile) => {

    return (
            <Grid
              item
              sx={{
                width: "100%",
                padding: "20px",
                height: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                },
              }}
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <CommonTile
                uid={tile.ListingId}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={ 
                  this.props.favorites.some(obj => obj.mls === tile.mls) ? 
                  <React.Fragment>
                    <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={200}/>              
                    </React.Fragment>              
                    :
                    <React.Fragment>
                    <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={200}/>              
                    </React.Fragment>                           
                }
                tagTopRightTxt={null}
                tagTopRightPadding={"2px 2px 2px 2px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"2px"}
                tagTopRightPaddingTop={"2px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,0)"}
                tagTopRightBorder={"0px solid white"}
                tagTopRightBorderRadius={["0px","0px","0px","0px"]}
                tagTopRightCallback={null}

                tagBottomRightIcon={
            tile.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={20} width={60} duration={200}/> : null
          }
          tagBottomRightTxt={
            null
          }
                tagBottomRightPadding={"5px 10px 5px 10px"}
                tagBottomRightTxtColor={"black"}
                tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagBottomRightPaddingRight={"0px"}
                tagBottomRightPaddingBottom={"0px"}
                tagBottomRightTxtType={"bold"}
                tagBottomRightTxtAlign={"center"}
                tagBottomRightBgColor={this.props.theme.highlightColor}
                tagBottomRightBorder={"0px solid black"}
                tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
                tagBottomRightCallback={null}

                tagTopLeftIcon={null}
                tagTopLeftTxt={
                  tile.status
                }
                tagTopLeftPadding={"5px 10px 5px 10px"}
                tagTopLeftTxtColor={tile.status === "Available" ? this.props.theme.bannerTextColor : "black"}
                tagTopLeftTxtSize={[".75rem", ".75rem", ".75rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"5px"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={tile.status === "Available" ? "rgba(12,101,58,.95)" : "rgba(255, 165, 0,.95)"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}

                tagBottomLeftIcon={null}
                tagBottomLeftTxt={tile.videos.length > 0 ?                 
                  <Button variant="filled" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".75rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
                    Video
                  </Button>                
                  : null
                }
                tagBottomLeftPadding={"0px"}
                tagBottomLeftTxtColor={this.props.theme.bannerColor}
                tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
                tagBottomLeftPaddingLeft={"0px"}
                tagBottomLeftPaddingBottom={"5px"}
                tagBottomLeftTxtType={"bold"}
                tagBottomLeftTxtAlign={"center"}
                tagBottomLeftBgColor={"rgba(255,255,255,1)"}
                tagBottomLeftBorder={"0px solid black"}
                tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
                tagBottomLeftCallback={this.showListingReport}

                topCallback={this.showListingReport}
                topPadding={"0px"}
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topTxtColor={"white"}
                topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgba(255,255,255,0)"}
                topHeight={["250px", "75%", "75%"]}
                topGraphType={null}
                topGraphData={null}
                topWidget={
                  <Image src={tile.image} fit="cover" duration={200} />
                }

                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxtLineHeight={1.5}
                mdlTxt={null}

                mdlWidget={
                  
                  <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                    <Grid
                      item
                      sx={{
                        background: "rgba(255,255,255,0)",
                        overflowX:"hidden",
                        textAlign:"left",
                        fontSize: {
                          xs:"1rem",
                          sm:"1rem",
                          md:"1rem",
                          lg:"1rem",
                          xl:"1rem",
                        },
                        fontWeight:"normal",
                      }}
                      xs={10}
                    >

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            fontWeight:"normal",
                          }}
                          xs={12}
                        >
                            <Box sx={{
                              fontSize:{
                              xs:"1.25rem",
                              sm: "1.25rem",
                              md:"1.25rem",
                              lg:"1.25rem",
                              xl:"1.25rem",
                              },
                              paddingLeft: {
                                xs:"10px",
                                sm:"10px",
                                md:tile.ListAgentMlsId === "WILLSDO" ? "5px" : "10px",
                                lg:"10px",
                                xl:"10px",
                              },
  
                              fontWeight:"bold", display:"inline"}} >
                          {tile.price}
                          </Box>
                            &nbsp;|&nbsp;
                          {tile.details.sqf} sf &nbsp;|&nbsp;
                          {tile.details.bed} bd&nbsp;|&nbsp;
                          {tile.details.bath} ba
                        </Grid>                           

                        <Grid
                          item
                          sx={{
                            overflowX:"hidden",
                            textAlign:"left",
                            paddingLeft: {
                              xs:"10px",
                              sm:"10px",
                              md:tile.ListAgentMlsId === "WILLSDO" ? "5px" : "10px",
                              lg:"10px",
                              xl:"10px",
                            },
                            fontSize: {
                              xs:"1rem",
                              sm: "1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem",
                            }
                          }}
                          xs={12}
                        >
                        {tile.address}
                      </Grid>                 
                    </Grid>                 
                    </Grid>                 

                    <Grid
                      item
                      sx={{
                        overflowX:"hidden",
                        textAlign:"left",
                        fontSize: {
                          xs:"1rem",
                          sm:"1rem",
                          md:"1rem",
                          lg:"1rem",
                          xl:"1rem",
                        },
                        fontWeight:"normal",
                        height:"100%",
                        width:"100%",     
                      }}
                      xs={2}
                    >

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-end"
                      sx={{
                        background: "rgba(255,255,255,0)",
                        height:"100%",
                        width:"100%",     
                        border: "0px solid #AAAAAA",
                        paddingBottom:
                        {
                          xl:"0px",
                        }

                      }}
                    >
                      {tile.ListAgentMlsId === "WILLSDO"?
                      <Image src={"profilepics/profile-round-lowres.png"} bgColor="rgba(255,255,255,0)" fit="contain" duration={200}/>              
                        :null}
                    </Grid>

                    </Grid>
                </Grid>            
                
                  }
                mdlTxtColor={ "black"}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"normal"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"flex-end"}
                mdlBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
                mdlBorder={ "0px solid black"}
                mdlHeight={["75px", tile.ListAgentMlsId === "WILLSDO" ? "20%" : "15%", tile.ListAgentMlsId === "WILLSDO" ? "20%" : "20%"]}
                mdlGraphType={null}
                mdlGraphData={null}                
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={ 
                  <center>
                    <div style={{fontSize:".5rem"}}>
                    <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {tile.mls} - Listing By {tile.broker}
                    </div>
                  </center>      
                }
                btmTxtColor={ "black"}
                btmTxtSize={[".75rem", ".75rem", ".75rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor: "#FFFFFF" }
                btmBorder={"0px solid black"}
                btmHeight={["25px", tile.ListAgentMlsId === "WILLSDO" ? "5%" : "10%", tile.ListAgentMlsId === "WILLSDO" ? "5%" : "5%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={tile.ListAgentMlsId === "WILLSDO" ? "5px solid " + this.props.theme.highlightColor : "5px solid #FFFFFF" }
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
          </Grid>
          );
  };

  getSelectedListing = () => {
    const filteredArr = this.state.featured.filter(obj => obj["id"] === this.state.listingId);
    return filteredArr.length ? filteredArr[0] : null;
  }
  
  render() {

 
    return (
      <React.Fragment>
      <Drawer
          anchor="bottom"
          open={this.props.showFeaturedListings}
          onClose={this.props.toggleFeaturedListings}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "86%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",
              marginLeft: "auto",
              pointerEvents:"auto",
              zINdex:this.props.zindex,

              backgroundImage: "url('"+ this.props.theme.background +"')",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

            },
          }}
        >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: {
            xs: "100%",
          },
          width: {
            xs: "100%",
          },
          
          borderTop:{
            xs:"1px solid #AAAAAA",
            sm: "0px solid #333333"
          }
        }}
      >

      <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "left",
              padding: "5px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              fontSize: {
                xs: "0px",
                sm: "0px",
                md: "1rem",
                lg: "1rem",
                xl: "1.5rem",
              },
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={4}
            md={4}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                padding: "0px",               
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:"1.5rem",
                  lg:"1.5rem",
                  xl:"1.5rem",
                },             
                color:this.props.theme.bannerTextColor  
              }}
            >
              &nbsp;&nbsp;{this.state.subService}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              background: this.props.theme.bannerColor,
              cursor: "pointer",
              textAlign: "center",
              padding: "10px",
              borderBottom: "0px solid #A0A0A0",
              fontWeight: "bold",
              height: {
                xs: "12%",
                sm: "12%",
                md: "12%",
                lg: "12%",
                xl: "12%",
              },
            }}
            xs={8}
            md={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                height: "100%",
                width: { md: "100%", xs: "100%" },
                paddingBottom: "0px",
              }}
            >
              <Button
                onClick={() => this.props.showContact()}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "10px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Contact Donna
              </Button>
              
              <Button
                onClick={this.state.showCommunities || this.state.showRecent || this.state.showWaterfront || this.state.showNewConstruction ? this.toggleSubServices : this.props.toggleFeaturedListings}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>        
            <Grid
              item
              sx={{
                padding: "0px",
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                overflowY: "auto",
                overflowX: "hidden",
                fontSize: "16px",
                border: "0px solid red",
                
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: "100%",
                  
                  border: "0px solid #333333",
                }}
              >   

                {this.createSection(
                this.sellingAHomeSection,
                "Howard Hanna Advantage",
                " When it comes to real estate, it's important to work with the best and we're the top FeaturedListingsage in the area. With a proven track record of success, we're committed to providing you with the highest level of service.",
                "When you choose to work with us, you can be confident that you're getting the best of the best."
              )}

            </Grid>
        </Grid>

      </Grid>

      </Drawer>
          <ListingReport
              parentPage="Featured"
              screenSize ={this.props.screenSize}
              listingid={this.state.listingid}
              listing={this.getSelectedListing()}
              requestShowing={this.props.requestShowing}
              showListingReport={this.state.showListingReport}
              toggleListingReport={this.toggleListingReport}
              getListingReport={this.props.getListingReport}
              displayRequestShowing={this.props.displayRequestShowing}
              showContact={this.showContact}
              favorites={this.props.favorites}
              markAsFavorite={this.props.markAsFavorite}
              theme={this.props.theme}
            />

        </React.Fragment>          
    );
  }
}

export default FeaturedListings;
