import MapComponent from "./MapComponent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from "./Footer";
import ListingReport from "./ListingReport";
import RequestShowing from "./RequestShowing";
import SearchSelect from './SearchSelect';
import Contact from './Contact';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GotoIcon from '@mui/icons-material/Room';
import Image from 'mui-image'
import Drawer from "@mui/material/Drawer";
import Grow from "@mui/material/Grow";
import Button from "@mui/material/Button";
import Speedometer from 'react-d3-speedometer';
import PlayIcon from '@mui/icons-material/PlayCircle';
import AlertDialog from './AlertDialog'
import _ from "lodash";


class SearchListings extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];
    this.listingContainerRef = React.createRef();
    this.state = {
      doIntro: true,
      appSize: "small",
      small:{
        mapWidth:7,
        tileHeight: "45%",        
        imageSize:"20px",
        fontSize:".8rem",
        fontSmSize:".5rem",
        fontLgSize:"1rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      large:{
        mapWidth:6,
        tileHeight: "60%",        
        imageSize:"24px",
        fontSize:"1rem",
        fontSmSize:".75rem",
        fontLgSize:"1.2rem",
        topHeight: "70%",
        mdlHeight: "20%",
        btmHeight: "10%"
      },
      mapWidth: 7,
      tileHeight: "40%",      
      showListingReport: false,
      showRequestShowing: false,
      showHowardHanna: false,
      showFinancing: false,
      showHomeValue: false,
      showSpotlightListings: false,
      showSearchListings: false,
      showBuyingAHome: false,
      showSellingAHome: false,
      showContact: false,
      gotoHome: null,
      favorites: [],
      searchResults: [],
    };

  }


  toggleListingReport = () => {
    this.setState({
      showListingReport: !this.state.showListingReport,
    });
  };

  displayListingReport = (listingid) => {
    this.setState({
      showListingReport: !this.state.showListingReport,
      listingId: listingid
    });
  }

  toggleRequestShowing = () => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
    });
  };

  showRequestShowing = (listings) => {
    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }


  showRequestShowingOfFavorites = (id) => {

    let listings =  this.state.searchResults.map((listing, index) => {
        if(this.props.favorites.some(obj => obj.id === id)){
          return listing
        }else{
          return null
        }
    }).filter((element) => element !== null);


    this.setState({
      showRequestShowing: !this.state.showRequestShowing,
      listingRequest: listings
    });
  }



  /*
  * Contact
  */
  toggleContact = () => {
    this.setState({
      showContact: !this.state.showContact,
    });
  };

  showContact = () => {
    this.setState({
      showContact: !this.state.showContact,
    });
  }

  

  /*
  * Toogel App Size
  */
  toggleAppSize = () => {
    if(this.state.appSize === "small"){
      this.setState({
        appSize:"large"
      })
    }else{
      this.setState({
        appSize:"small"
      })
    }
  }

  getTestimonialTile = (tile) => {
    return(<Grid
    item
    sx={{
      
      padding: "5px",
      height: {
        xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
        lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
        md: "65%",
        sm: "65%",
      },
      background: "#FFFFFF",
    }}
    xl={6}
    lg={6}
    md={12}
    sm={12}
    xs={12}
  >

    <CommonTile
      uid={tile.ListingId}
      animationType={null}
      animationContainer = {null}
      animationTime={null}
      tagTopRightIcon={ null }
      tagTopRightTxt={null}
      tagTopRightPadding={"5px"}
      tagTopRightTxtColor={"black"}
      tagTopRightTxtSize={["1.5rem", "1.5rem", "1rem"]}
      tagTopRightPaddingRight={"4px"}
      tagTopRightPaddingTop={"4px"}
      tagTopRightTxtType={"bold"}
      tagTopRightTxtAlign={"center"}
      tagTopRightBgColor={"rgba(255,255,255,.5)"}
      tagTopRightBorder={"0px solid black"}
      tagTopRightBorderRadius={"5px"}
      tagTopRightCallback={null}

      tagBottomRightIcon={null}
      tagBottomRightTxt={null}
      tagBottomRightPadding={"5px"}
      tagBottomRightTxtColor={"black"}
      tagBottomRightTxtSize={["1.5rem", "1.5rem", "1rem"]}
      tagBottomRightPaddingRight={"5px"}
      tagBottomRightPaddingBottom={"5px"}
      tagBottomRightTxtType={"bold"}
      tagBottomRightTxtAlign={"center"}
      tagBottomRightBgColor={"rgba(255,255,255,.5)"}
      tagBottomRightBorder={"0px solid black"}
      tagBottomRightBorderRadius={"5px"}
      tagBottomRightCallback={null}

      tagTopLeftIcon={null}
      tagTopLeftTxt={null}
      tagTopLeftPadding={"5px 20px 5px 20px"}
      tagTopLeftTxtColor={"black"}
      tagTopLeftTxtSize={["1rem", "1rem", this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
      tagTopLeftPaddingLeft={"0px"}
      tagTopLeftPaddingTop={"0%"}
      tagTopLeftTxtType={"bold"}
      tagTopLeftTxtAlign={"center"}
      tagTopLeftBgColor={"#73be73"}
      tagTopLeftBorder={"0px solid black"}
      tagTopLeftBorderRadius={"0px"}

      tagBottomLeftIcon={null}
      tagBottomLeftTxt={null}
      tagBottomLeftPadding={"10px"}
      tagBottomLeftTxtColor={"#333333"}
      tagBottomLeftTxtSize={["1rem", "1rem", "1rem"]}
      tagBottomLeftPaddingLeft={"5px"}
      tagBottomLeftPaddingBottom={"5px"}
      tagBottomLeftTxtType={"bold"}
      tagBottomLeftTxtAlign={"center"}
      tagBottomLeftBgColor={"rgba(255,255,255,.9)"}
      tagBottomLeftBorder={"0px solid black"}
      tagBottomLeftBorderRadius={"5px"}
      tagBottomLeftCallback={null}

      topCallback={null}
      topPadding={"0px"}
      topPicture={null}
      topPictureAlign={"center"}
      topPictureSize={"cover"}
      topTxt={
        <div
        sx={{
          border: "0px solid black",
          width: "80%",
          lineSpacing: "1.25",
          padding: "5px",
          background: "rgba(255,525,255,0)",
        }}
        >What a fantastic realtor!  Donna was great, my situation was so stressful and she put me at ease and I look forward to  working with her again.  I highly recommend her to anyone in the market buying or selling!
        <br /><br />
          &nbsp;&nbsp;&nbsp;&nbsp;Randy S.
        </div>
      }
      topTxtColor={"black"}
      topTxtSize={["1.25rem", "1rem",  this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
      topTxtType={"normal"}
      topTxtAlign={"left"}
      topTxtLineHeight={"1.5"}
      topVerticalAlign={"center"}
      topHorizontalAlign={"center"}
      topBgColor={"rgba(255,255,255,1)"}
      topHeight={["300px", "100%", "100%"]}
      topGraphType={null}
      topGraphData={null}
      mdlTagIcon={null}
      mdlTagTxt={null}
      mdlTagPadding={"0px"}
      mdlTagTxtColor={"black"}
      mdlTagTxtSize={["1.5rem", "1.5rem", "1rem"]}
      mdlTagPaddingRight={"4px"}
      mdlTagPaddingTop={"2px"}
      mdlTagTxtType={"bold"}
      mdlTagTxtAlign={"center"}
      mdlTagBgColor={"rgba(255,255,255,0)"}
      mdlTagBorder={"0px solid red"}
      mdlTagBorderRadius={"0px"}                
      mdlPadding={"0px"}
      mdlPicture={null}
      mdlPictureAlign={null}
      mdlTxtLineHeight={0}
      mdlTxt={ null }
      mdlTxtColor={"black"}
      mdlTxtSize={["1rem", "1.0rem", "1rem"]}
      mdlTxtType={"bold"}
      mdlTxtAlign={"center"}
      mdlHorizontalAlign={"center"}
      mdlVerticalAlign={"flex-end"}
      mdlBgColor={"#FFFFFF"}
      mdlBorder={"0px solid black"}
      mdlHeight={["0px", "0%", "0%"]}
      mdlGraphType={null}
      mdlGraphData={null}                
      btmPadding={"0px"}
      btmPicture={null}
      btmPictureAlign={null}
      btmTxt={ null }
      btmTxtColor={"#333333"}
      btmTxtSize={[".75rem", ".75rem", "1rem"]}
      btmTxtType={"normal"}
      btmTxtAlign={"left"}
      btmHorizontalAlign={"center"}
      btmVerticalAlign={"center"}
      btmBgColor={"white"}
      btmBorder={"0px solid black"}
      btmHeight={["0rem", "0%", "0%"]}
      btmGraphType={null}
      btmGraphData={null}
      border={"1px solid #AAAAAA"}
      shadow={4}
      downloads={""}
      linksTo={""}
      growTime={4000}
      padding={"0px"}
      borderRadius={null}
      bgcolor={"rgba(255,255,255,1)"}
    />


  </Grid>)

  }

  getListingTile = (tile) => {

    /*
      {
        "BathroomsFull": 2,
        "LivingArea": 1872.0,
        "BedroomsTotal": 3,
        "PostalCity": "Baldwinsville",
        "Latitude": 43.19723,
        "PropertyType": "Residential",
        "ListPrice": 219000.0,
        "BathroomsHalf": 1,
        "Longitude": -76.357404,
        "UnparsedAddress": "2169 Kellogg Road",
        "OnMarketDate": "2024-03-01",
        "DaysOnMarket": 5,
        "ListingId": "S1523630",
        "ListOfficeName": "Howard Hanna Real Estate",
        "ListingKey": "1061919948",
        "Media": [
            {
                "MediaURL": "https://api-trestle.corelogic.com/trestle/Media/NYS/Property/PHOTO-jpeg/1061919948/1/NzM4Ny82ODUxLzE3/MTcvMTczMDEvMTcwOTc4MzA1OA/ZdzK405e75-ykB61cYWG5GZfxgt0E8QuDSOgTxOLtpU",
                "X_MediaStream": null
            },
     */  

    return(
        <Grid
        item
        sx={{
          
          padding: "5px",
          height: {
            xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
            lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
            md: "65%",
            sm: "55%",
            xs: "55%",
          },
          width:"100%",
          background: "#FFFFFF",
        }}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
      >
        <CommonTile
          uid={tile.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ 
            this.props.favorites.some(obj => obj.ListingId === tile.ListingId) ? 
            <React.Fragment>
               <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={100}/>              
              </React.Fragment>              
              :
              <React.Fragment>
               <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(tile)} fit="fit" height={35} width={55} duration={100}/>              
              </React.Fragment>                           
          }
          tagTopRightTxt={null}
          tagTopRightPadding={"2px 2px 2px 2px"}
          tagTopRightTxtColor={"black"}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"2px"}
          tagTopRightPaddingTop={"2px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={"rgba(255,255,255,0)"}
          tagTopRightBorder={"0px solid white"}
          tagTopRightBorderRadius={["0px","0px","0px","0px"]}
          tagTopRightCallback={null}

          tagBottomRightIcon={
            tile.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={20} width={60} duration={100}/> : null
          }
          tagBottomRightTxt={
            null
          }
          tagBottomRight          
          tagBottomRightPadding={"5px 10px 5px 10px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
          tagBottomRightPaddingRight={"0px"}
          tagBottomRightPaddingBottom={"0px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={this.props.theme.highlightColor}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
          tagBottomRightCallback={null}

          tagTopLeftIcon={null}
          tagTopLeftTxt={
            tile.status
          }
          tagTopLeftPadding={"5px 10px 5px 10px"}
          tagTopLeftTxtColor={tile.status === "Available" ? this.props.theme.bannerTextColor : "black"}
          tagTopLeftTxtSize={[".75rem", ".75rem",  this.state.appSize === "small" ? ".75rem" : ".75rem"]}
          tagTopLeftPaddingLeft={"0px"}
          tagTopLeftPaddingTop={"5px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={tile.status === "Available" ? "rgba(12,101,58,.95)" : "rgba(255, 165, 0,.95)"}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}

          tagBottomLeftIcon={null}
          tagBottomLeftTxt={tile.videos && tile.videos.length > 0 ?                 
            <Button variant="filled" size="small" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".55rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
              Video
            </Button>                
            : null
          }
          tagBottomLeftPadding={"0px"}
          tagBottomLeftTxtColor={this.props.theme.bannerColor}
          tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
          tagBottomLeftPaddingLeft={"0px"}
          tagBottomLeftPaddingBottom={"5px"}
          tagBottomLeftTxtType={"bold"}
          tagBottomLeftTxtAlign={"center"}
          tagBottomLeftBgColor={"rgba(255,255,255,1)"}
          tagBottomLeftBorder={"0px solid black"}
          tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
          tagBottomLeftCallback={this.displayListingReport}

          topCallback={this.displayListingReport}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["250px", "75%", this.state.appSize === "small" ? this.state.small.topHeight : this.state.large.topHeight]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            tile.Media && tile.Media.length > 0 ?
            <Image src={tile.Media[0]["MediaURL"]} fit="cover" duration={100} />
            :
            null
          }

          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={
            
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "rgba(255,255,255,0)",
              height:"100%",
              width:"100%",     
              border: "0px solid #AAAAAA",
            }}
          >
              <Grid
                item
                sx={{
                  background: "rgba(255,255,255,0)",
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                }}
                xs={9}
                sm={9}
                md={9}
                lg={10}
                xl={10}
                
              >

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:"1rem",
                        lg:this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize,
                        xl:this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize,
                      },
                      fontWeight:"normal",
                    }}
                    xs={12}
                  >
                      <Box sx={{
                        fontSize:{
                        xs:"1.25rem",
                        sm: "1.25rem",
                        md:"1.25rem",
                        lg:this.state.appSize === "small" ? this.state.small.fontLgSize : this.state.large.fontLgSize,
                        xl:this.state.appSize === "small" ? this.state.small.fontLgSize : this.state.large.fontLgSize,
                        },
                        paddingLeft:"10px",
                        fontWeight:"bold", display:"inline"}} >
                    {"$"+ (tile.ListPrice ? tile.ListPrice.toLocaleString():"--")}
                    </Box>
                      &nbsp;|&nbsp;
                    {tile.LivingArea ? tile.LivingArea.toLocaleString() : "--"} sf &nbsp;|&nbsp;
                    {tile.BedroomsTotal} bd&nbsp;|&nbsp;
                    {tile.BathroomsFull}.{tile.BathroomsHalf > 0 ? "5":"0"} ba
                  </Grid>                           

                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      paddingLeft:"10px",
                      fontSize: {
                        xs: "1rem",
                        lg:this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize,
                      },
                      lineHeight:"1",
                      overflowY:"hidden"
                    }}
                    xs={12}
                  >
                  {tile.UnparsedAddress + ", " + tile.PostalCity}
                </Grid>                 
              </Grid>                 
              </Grid>                 

              <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                  height:"100%",
                  width:"100%",     
                }}
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                
              >

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  background: "rgba(255,255,255,0)",
                  height:"100%",
                  width:"100%",     
                  border: "0px solid #AAAAAA",
                  cursor:"pointer",
                  paddingBottom:
                  {
                    lg:"0px",
                  },
                
                }}
                onClick = {() => this.gotoHome(tile.ListingId)}
              >
                {tile.ListAgentMlsId === "WILLSDO"?
                 <Image src={"profilepics/profile-round-lowres.png"} bgColor="rgba(255,255,255,0)" fit="contain" duration={100}/>              
                  :
                  <Image src={"mapmarkers/goto-marker-green.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={40} width={30} duration={100}/> 
              }
              </Grid>

              </Grid>
           </Grid>            
          
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem",  this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
          mdlBorder={tile.ListAgentMlsId === "WILLSDO" ? "1px solid " + this.props.theme.highlightColor : "0px solid black"}
          mdlHeight={["75px", tile.ListAgentMlsId === "WILLSDO" ? "20%" : "15%", this.state.appSize === "small" ? this.state.small.mdlHeight : this.state.large.mdlHeight]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"0px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ 
            <center>
              <div style={{fontSize:".6rem"}}>
              <img alt="image" src="howardhanna/idx-logo-sm.png" height="15px"/>  MLS:  {tile.ListingId} - Listing By {tile.ListOfficeName}
              </div>
            </center>      
          }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".75rem", this.state.appSize === "small" ? this.state.small.fontSmSize : this.state.large.fontsmSize]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={tile.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor: "#FFFFFF" }
          btmBorder={"0px solid black"}
          btmHeight={["25px", tile.ListAgentMlsId === "WILLSDO" ? "5%" : "10%", this.state.appSize === "small" ? this.state.small.btmHeight : this.state.large.btmHeight]}
          btmGraphType={null}
          btmGraphData={null}
          border={tile.ListAgentMlsId === "WILLSDO" ? "2px solid " + this.props.theme.highlightColor : "2px solid #FFFFFF" }
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />
      </Grid>
      );
  }

  updateTiles = (searchResults) => {
    this.setState({
      searchResults: searchResults
    })
  }

  displayTiles = () => {
    return (
      <React.Fragment>
        {this.state.searchResults && this.state.searchResults.map((tile, index) => {
          return (
            index%4 === 0 && index > 0 ? 
              <React.Fragment>
                {tile.ListingId ? this.getListingTile(tile) : null} 
              </React.Fragment>
              : 
              tile.ListingId ? this.getListingTile(tile) : null 
          );
        })}
      </React.Fragment>
    );
  };

  gotoHome = (uid) => {
    this.state.searchResults.forEach( home => {
      if(home.ListingId === uid){
        this.setState({
          gotoHome: {"lat":home.Latitude, "lng":home.Longitude}
        })
      }
    })
  }


  getContactTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "5px",
            height: {
              xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
              lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
  
              md: "65%",
              sm: "35%",
              xs: "35%",
            },
            background: "#FFFFFF",
          }}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"profilepics/contact-profile.png"}
            topPictureAlign={"50% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "75%", "75%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", "1rem",this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"white"}
            mdlBorder={"0px solid #AAAAAA"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <React.Fragment>      
              <Button
              onClick={() => this.props.showContact("SearchListings")}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"85%",
                padding: this.state.appSize === "small" ? "10px" : "10px",
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Request More Information</Button>
              </React.Fragment>              

            }
            btmWidget={null}
            btmCallback={null}
            btmTxtColor={this.props.theme.bannerTextColor}
            btmTxtSize={["14px", "1rem", this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={this.props.theme.bannerColor}
            btmBorder={"1px solid " + this.props.theme.bannerColor}
            btmHeight={["100px", "25%", "25%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"5px solid " + this.props.theme.bannerColor}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"white"}
          />
        </Grid>
      </React.Fragment>
    );
    };

    getJoinTheTeamTile = () => {
      return (
        <React.Fragment>
          <Grid
            item
            sx={{
              
              padding: "5px",
              height: {
                xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
                lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
    
                md: "65%",
                sm: "35%",
                xs: "35%",
              },
              background: "#FFFFFF",
            }}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <CommonTile
              animationType={null}
              animationTime={null}
              tagPicture={null}
              tagTxt={null}
              tagTxtColor={"black"}
              tagTxtSize={["12px", "12px", "16px"]}
              tagTxtType={"bold"}
              tagTxtAlign={"center"}
              tagVerticalAlign={"flex-end"}
              tagHorizontalAlign={"flex-end"}
              tagBgColor={"#73be73"}
              tagBorder={"1px solid black"}
              tagBorderRadius={"0px"}
              topPadding={"25px"}
              topPicture={"profilepics/join-the-team-bll-at-hh.png"}
              topPictureAlign={"50% 100%"}
              topPictureSize={"contain"}
              topTxt={null}
              topTxtColor={"black"}
              topTxtSize={["0px", "6px", "20px"]}
              topTxtType={"plain"}
              topTxtAlign={"right"}
              topHorizontalAlign={"flex-end"}
              topVerticalAlign={"flex-end"}
              topBgColor={"rgba(255,255,255,0)"}
              topHeight={["250px", "75%", "75%"]}
              topGraphType={null}
              topGraphData={null}
              mdlPadding={"0px"}
              mdlPicture={null}
              mdlPictureAlign={null}
              mdlTxt={null}
              mdlCallback={null}
              mdlTxtColor={"#333333"}
              mdlTxtSize={["16px", "1rem",this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
              mdlTxtType={"normal"}
              mdlTxtAlign={"center"}
              mdlHorizontalAlign={"center"}
              mdlVerticalAlign={"center"}
              mdlBgColor={"white"}
              mdlBorder={"0px solid #AAAAAA"}
              mdlHeight={["0px", "0%", "0%"]}
              mdlGraphType={null}
              mdlGraphData={null}
              btmPadding={"0px"}
              btmPicture={null}
              btmPictureAlign={null}
              btmTxt={
                <React.Fragment>      
                <Button
                onClick={() => this.props.showContact("SearchLIstings")}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.buttonTextColor,
                    background:this.props.theme.buttonHoverColor
                  },
      
                  width:"85%",
                  padding: this.state.appSize === "small" ? "10px" : "10px",
                  marginRight: "0px",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                  textTransform: "none",
                  textAlign: "center",
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.buttonColor,
                  fontSize:{
                    xs:"1rem",
                    sm:"1rem",
                    md:".75rem",
                    lg:".75rem",
                    xl:".75rem",
  
                  }
                }}
              >Request More Information</Button>
                </React.Fragment>              
  
              }
              btmWidget={null}
              btmCallback={null}
              btmTxtColor={this.props.theme.bannerTextColor}
              btmTxtSize={["14px", "1rem", this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
              btmTxtType={"normal"}
              btmTxtAlign={"center"}
              btmHorizontalAlign={"center"}
              btmVerticalAlign={"center"}
              btmBgColor={this.props.theme.bannerColor}
              btmBorder={"1px solid " + this.props.theme.bannerColor}
              btmHeight={["100px", "25%", "25%"]}
              btmGraphType={null}
              btmGraphData={null}
              border={"5px solid " + this.props.theme.bannerColor}
              shadow={4}
              downloads={""}
              linksTo={""}
              growTime={4000}
              padding={"0px"}
              borderRadius={null}
              bgcolor={"white"}
            />
          </Grid>
        </React.Fragment>
      );
      };
  

  getListingApptTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "5px",
            height: {
              xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
              lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
  
              md: "65%",
              sm: "35%",
              xs: "35%",
            },
            background: "#FFFFFF",
          }}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"downloads/full-report-3.png"}
            topPictureAlign={"50% 50%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "75%", "75%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", "1rem",this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"white"}
            mdlBorder={"0px solid #AAAAAA"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              <React.Fragment>      
              <Button
              onClick={() => this.props.showContact("SearchListings")}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"85%",
                padding: this.state.appSize === "small" ? "10px" : "10px",
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Schedule A Home Valuation</Button>
              </React.Fragment>              

            }
            btmWidget={null}
            btmCallback={null}
            btmTxtColor={this.props.theme.bannerTextColor}
            btmTxtSize={["14px", "1rem", this.state.appSize === "small" ? this.state.small.fontSize : this.state.large.fontSize]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={this.props.theme.bannerColor}
            btmBorder={"1px solid #CCCCCC"}
            btmHeight={["100px", "25%", "25%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"5px solid "+ this.props.theme.bannerColor}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"white"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getScoreCardTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "5px",
            height: {
              xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
              lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
  
              md: "65%",
              sm: "35%",
              xs: "35%",
            },
            background: "#FFFFFF",
          }}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
        <CommonTile
        animationType={null}
        animationTime={null}
        tagPicture={null}
        tagTopLeftIcon={null}
        tagTopLeftTxt={null}
        tagTopLeftPadding={"5px 10px 5px 10px"}
        tagTopLeftTxtColor={"black"}
        tagTopLeftTxtSize={null}
        tagTopLeftPaddingLeft={"0px"}
        tagTopLeftPaddingTop={"0%"}
        tagTopLeftTxtType={"bold"}
        tagTopLeftTxtAlign={"center"}
        tagTopLeftBgColor={null}
        tagTopLeftBorder={"0px solid black"}
        tagTopLeftBorderRadius={"0px"}
        topPadding={"25px"}
        topPicture={"downloads/my-services-scorecard.jpg"}
        topPictureAlign={"50% 100%"}
        topPictureSize={"contain"}
        topTxt={null}
        topTxtColor={"black"}
        topTxtSize={["0px", "6px", "20px"]}
        topTxtType={"plain"}
        topTxtAlign={"right"}
        topHorizontalAlign={"flex-end"}
        topVerticalAlign={"flex-end"}
        topBgColor={"rgba(255,255,255,0)"}
        topHeight={["250px", "75%", "75%"]}
        topGraphType={null}
        topGraphData={null}
        mdlPadding={"0px"}
        mdlPicture={null}
        mdlPictureAlign={null}
        mdlTxt={<React.Fragment>              
          <Button
          onClick={() => this.downloadScorecardInformation()}
          variant={this.props.theme.buttonVariant}
          sx={{
            '&:hover': {
              border:"1px solid " + this.props.theme.buttonTextColor,
              background:this.props.theme.buttonHoverColor
            },

            width:"85%",
            padding: this.state.appSize === "small" ? "10px" : "10px",
            marginRight: "0px",
            borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
            textTransform: "none",
            textAlign: "center",
            color:this.props.theme.buttonTextColor,
            border:"1px solid " + this.props.theme.buttonColor,
            fontSize:{
              xs:"1rem",
              sm:"1rem",
              md:".75rem",
              lg:".75rem",
              xl:".75rem",

            }
          }}
        >See What's In A Scorecard</Button>
          </React.Fragment>           
        }
        mdlCallback={null}
        mdlTxtColor={this.props.theme.bannerTextColor}
        mdlTxtSize={["16px", "1rem", "1rem"]}
        mdlTxtType={"normal"}
        mdlTxtAlign={"center"}
        mdlHorizontalAlign={"center"}
        mdlVerticalAlign={"center"}
        mdlBgColor={this.props.theme.bannerColor}
        mdlBorder={"1px solid black"}
        mdlHeight={["100px", "25%", "25%"]}
        mdlGraphType={null}
        mdlGraphData={null}
        btmPadding={"0px"}
        btmPicture={null}
        btmPictureAlign={null}
        btmTxt={null}
        btmCallback={null}
        btmTxtColor={"white"}
        btmTxtSize={["16px", "1rem", "1rem"]}
        btmTxtType={"normal"}
        btmTxtAlign={"center"}
        btmHorizontalAlign={"center"}
        btmVerticalAlign={"center"}
        btmBgColor={"rgba(25, 118, 210, 1)"}
        btmBorder={"0px solid black"}
        btmHeight={["0px", "0%", "0%"]}
        btmGraphType={null}
        btmGraphData={null}
        border={"5px solid "+ this.props.theme.bannerColor}
        shadow={4}
        downloads={""}
        linksTo={""}
        growTime={4000}
        padding={"0"}
        borderRadius={null}
        bgcolor={"rgba(255,255,255,1)"}
      />
        </Grid>
      </React.Fragment>
    );
  };

  getMyServicesTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "5px",
            height: {
              xl: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
              lg: this.state.appSize === "small" ? this.state.small.tileHeight : this.state.large.tileHeight,
  
              md: "65%",
              sm: "35%",
              xs: "35%",
            },
            background: "#FFFFFF",
          }}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
        >
        <CommonTile
        animationType={null}
        animationTime={null}
        tagPicture={null}
        tagTopLeftIcon={null}
        tagTopLeftTxt={null}
        tagTopLeftPadding={"5px 10px 5px 10px"}
        tagTopLeftTxtColor={"black"}
        tagTopLeftTxtSize={null}
        tagTopLeftPaddingLeft={"0px"}
        tagTopLeftPaddingTop={"0%"}
        tagTopLeftTxtType={"bold"}
        tagTopLeftTxtAlign={"center"}
        tagTopLeftBgColor={null}
        tagTopLeftBorder={"0px solid black"}
        tagTopLeftBorderRadius={"0px"}
        topPadding={"25px"}
        topPicture={"downloads/my-services-tile.png"}
        topPictureAlign={"50% 100%"}
        topPictureSize={"contain"}
        topTxt={null}
        topTxtColor={"black"}
        topTxtSize={["0px", "6px", "20px"]}
        topTxtType={"plain"}
        topTxtAlign={"right"}
        topHorizontalAlign={"flex-end"}
        topVerticalAlign={"flex-end"}
        topBgColor={"rgba(255,255,255,0)"}
        topHeight={["250px", "75%", "75%"]}
        topGraphType={null}
        topGraphData={null}
        mdlPadding={"0px"}
        mdlPicture={null}
        mdlPictureAlign={null}
        mdlTxt={<React.Fragment>              
          <Button
          onClick={() => this.downloadServicesInformation()}
          variant={this.props.theme.buttonVariant}
          sx={{
            '&:hover': {
              border:"1px solid " + this.props.theme.buttonTextColor,
              background:this.props.theme.buttonHoverColor
            },

            width:"85%",
            padding: this.state.appSize === "small" ? "10px" : "10px",
            marginRight: "0px",
            borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
            textTransform: "none",
            textAlign: "center",
            color:this.props.theme.buttonTextColor,
            border:"1px solid " + this.props.theme.buttonColor,
            fontSize:{
              xs:"1rem",
              sm:"1rem",
              md:".75rem",
              lg:".75rem",
              xl:".75rem",

            }
          }}
        >Learn About All My Services</Button>
          </React.Fragment>           
        }
        mdlCallback={null}
        mdlTxtColor={this.props.theme.bannerTextColor}
        mdlTxtSize={["16px", "1rem", "1rem"]}
        mdlTxtType={"normal"}
        mdlTxtAlign={"center"}
        mdlHorizontalAlign={"center"}
        mdlVerticalAlign={"center"}
        mdlBgColor={this.props.theme.bannerColor}
        mdlBorder={"1px solid black"}
        mdlHeight={["100px", "25%", "25%"]}
        mdlGraphType={null}
        mdlGraphData={null}
        btmPadding={"0px"}
        btmPicture={null}
        btmPictureAlign={null}
        btmTxt={null}
        btmCallback={null}
        btmTxtColor={"white"}
        btmTxtSize={["16px", "1rem", "1rem"]}
        btmTxtType={"normal"}
        btmTxtAlign={"center"}
        btmHorizontalAlign={"center"}
        btmVerticalAlign={"center"}
        btmBgColor={"rgba(25, 118, 210, 1)"}
        btmBorder={"0px solid black"}
        btmHeight={["0px", "0%", "0%"]}
        btmGraphType={null}
        btmGraphData={null}
        border={"5px solid "+ this.props.theme.bannerColor}
        shadow={4}
        downloads={""}
        linksTo={""}
        growTime={4000}
        padding={"0"}
        borderRadius={null}
        bgcolor={"rgba(255,255,255,1)"}
      />
        </Grid>
      </React.Fragment>
    );
  };


  getIdxTile = () => {

    const currentDate = new Date();

    // Get day, month, year components
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
    const year = currentDate.getFullYear();

    // Get time components
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const localTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Format the output
    const formattedTimestamp = `${day}/${month}/${year} ${hours}:${paddedMinutes}:${seconds} ${localTimeZoneName}`;

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
           
            padding: "0px",
            height: {
              xl: "30%",
              lg: "30%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"5px"}
            topPicture={"howardhanna/idx-lg.png"}
            topPictureAlign={"left"}
            topPictureSize={"60px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "32px", "16px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["100px", "25%", "25%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["16px", "16px", "16px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"#FFFFFF"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}

            btmPadding={"0px 5px 5px 5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmOverflow={"hidden"}
            btmTxt={
              <div>
              <div>The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX)  
              Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
              with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
              may not be used for any purpose other than to identify prospective properties that consumers may be 
              interested in purchasing. Listing Data last updated at { formattedTimestamp }</div>
              <br/>
              <div>Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
              properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real  
              Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
              totally harmless. © {year} . CNYIS, UNYREIS, WNYREIS. All rights reserved.</div>
              </div>
            }
            btmTxtColor={"black"}
            btmTxtSize={[".7rem", ".6rem", ".6rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"0px solid black"}
            btmHeight={["450px", "75%", "75%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid #AAAAAA"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  downloadServicesInformation = () => {
    this.setState({ inProgress: true }, () => {
      this.props.getInformationDownload(
        this.props.parentPage,
        "services",
        this.informationCallback,
        )});
  }

  downloadScorecardInformation = () => {
    this.setState({ inProgress: true }, () => {
      this.props.getInformationDownload(
        this.props.parentPage,
        "scorecard",
        this.informationCallback,
        )});
  }


  informationCallback  = (response) => {

    if(response.data.message){
      const base64String = response.data.message;

      // Convert base64 string to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      // Create a download link and trigger click
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = response.data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    this.setState({inProgress:false})
  }

  getSelectedListing = () => {
    const filteredArr = this.state.searchResults.filter(obj => obj["ListingId"] === this.state.listingId);
    return filteredArr.length ? filteredArr[0] : null;
  }

  getFooter = () => {
    return (
      <Grid
      item
      sx={{
        width: {
          xs:"100%",
          xm:"100%",
          md:"100%",
          lg:"100%",
          xl:"100%"
        },
        height: {
          xl: "10%",
          lg: "10%",
          md: "10%",
          sm: "10%",
        },
        padding: "5px",
        fontSize: "2rem",
      }}
      xs={12}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          float:"right",
          height: "100%",
          width: {
            xs:"100%",
            sm:"100%",
            md:"100%",
            lg:"100%",
            xl:"100%"
          },

          paddingRight:"10px"
        }}
      >
            <Grid
            item
            sx={{
              paddingTop:"0px",
              width: "100%",
              height: "100%",
              fontSize:{
                xs:".5rem",
                sm:".5rem",
                md:".75rem",
                lg:".75rem",
              },
              float:"right"
            }}
            xs={8}
            sm={8}
            md={7}
            lg={9}
            xl={9}
        >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                padding:"5px",
                border:"0px solid black",
                float:"right",
                height: "100%",
                fontSize: {
                  xs:".5rem",
                  sm:".5rem",
                  md:".65rem",
                  lg:".65rem",
                  xl:".65rem"
                },
                color:"#555555",
                width: {
                  xs:"100%",
                  sm:"100%",
                  md:"100%",
                  lg:"100%",
                  xl:"100%"
                },
              }}
            >

&#169; 2024 Donna Wills Lic Real Estate Salesperson (315) 723-1077, DonnaWills@HowardHanna.com. <br/> Howard Hanna Real Estate Services (315) 487-0040
             </Grid>
           </Grid>
              <Grid
              item
              sx={{
                float:"right",
                paddingTop:"0px",
                width: "100%",
                height:"100%",
              }}
              xs={4}
              sm={4}
              md={5}
              lg={3}
              xl={3}
            >
           <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                border:"0px solid black",
                float:"right",
                height:"100%",
                width: {
                  xs:"100%",
                  sm:"100%",
                  md:"100%",
                  lg:"100%",
                  xl:"100%"
                },

                paddingRight:"0px",
                paddingLeft:"0px"
              }}
            >
                <Box
                  component="img"
                  src="howardhanna/hh-services.png"
                  alt="Image description"
                  sx={{
                    height: {
                      xs:"35px",
                      sm:"35px",
                      md:"50px",
                      lg:"50px",
                      xl:"50px",
                    },
                    objectFit: 'contain',
                    paddingRight:{
                      xs:"5px",
                      sm:"5px",
                      md:"10px",
                      lg:"10px",
                      xl:"10px"
                    },
                    paddingLeft:{
                      xs:"0px",
                      sm:"0px",
                      md:"5px",
                      lg:"5px",
                      xl:"5px"
                    },
                    cursor: "pointer",

                  }}
                  onClick = {this.gotoHowardHanna}
                />
                <Box
                  component="img"
                  src="howardhanna/fair-housing.png"
                  alt="Image description"
                  sx={{
                    border:"0px solid black",
                    height: {
                      xs:"35px",
                      sm:"35px",
                      md:"50px",
                      lg:"45px",
                      xl:"45px",
                    },
                    objectFit: 'contain',
                    paddingRight:{
                      xs:"0px",
                      sm:"0px",
                      md:"5px",
                      lg:"5px",
                      xl:"5px"
                    },
                    cursor: "pointer",

                  }}
                />
            </Grid>                     
        </Grid>
      </Grid>

    </Grid>
    )
  } 
  
  setMapExtents = (mapExtents) => {
    this.setState({
      searchExtents: mapExtents
    })
  }

  render() {

    return(

      
        <Drawer
          anchor="bottom"
          open={this.props.showSearchListings}
          onClose={this.props.toggleSearchListings}
          ModalProps={{
            // Setting this prop to false will create a non-modal drawer
            hideBackdrop: true,
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
            // Setting this prop to false will create a non-modal drawer
            disablePortal: true,
          }}
          variant="persistent"
          PaperProps={{
            sx: {
              height: {
                xs: "88%",
                sm: "85%",
                md: "90%",
                lg: "90%",
                xl: "90%",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              border: "0px solid gray",

              marginLeft: "auto",
              pointerEvents:"auto",
            },
          }}
        >  
        

        {false && this.props.showSearchListings && (
          <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.25)', // Semi-transparent black overlay
            zIndex: 1, // Ensure overlay is above the grid
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color:"#FFFFFF"
          }}
        >
          <AlertDialog 
            theme={this.props.theme} 
            open={this.props.showSearchListings}  
            yesButtonLabel="Ok" 
            yesButtonAction={this.props.toggleSearchListings} 
            closeAlertDialog={this.props.toggleSearchListings} 
            title="Coming Soon" 
            message="My Search Feature is almost ready"/>

        </div>

        )}

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "#F0F0F0",
              height: {
                xs: "100%",
              },
              width: {
                xs: "100%",
              },
            }}
          >    
            <Grid
              item
              sx={{
                
                height: {
                  xl: "12%",
                  lg: "12%",
                  md: "12%",
                  xs: "400px",
                  xs: "400px",
                },
                background: this.props.theme.bannerColor,
                padding: "2px",
                fontSize: "16px",
                borderBottom: "1px solid #AAAAAA",
              }}
              xs={12}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "100%",              
                  border: "0px solid #AAAAAA",
                }}
              >

                <SearchSelect searchExtents={this.state.searchExtents} toggleAppSize={this.toggleAppSize} getSearchResults={this.props.getSearchResults} updateTiles={this.updateTiles} toggleSearchListings={this.props.toggleSearchListings} theme={this.props.theme}/>

              </Grid>
            </Grid>    
            <Grid
              item
              sx={{
                
                height: {
                  xl: "88%",
                  lg: "88%",
                  md: "88%",
                  sm: "88%",
                },
                background: "#FFFFFF",
                padding: "5px",
                fontSize: "16px",
                borderTop: "0px solid green",
              }}
              xs={12}
            >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "#FF0F0",
                height: "100%",
                
                border: "0px solid #AAAAAA",
              }}
            >
              <Grid
                item
                sx={{
                  
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  background: "#FFFFFF",
                  padding: "5px",
                  fontSize: "16px",
                  borderRight: "0px solid #AAAAAA",
                }}
                xs={12}
                sm={12}
                md={7}
                lg={this.state.appSize === "small" ? this.state.small.mapWidth:this.state.large.mapWidth}
                xl={this.state.appSize === "small" ? this.state.small.mapWidth:this.state.large.mapWidth}
              >
                <Paper
                  sx={{
                    width:"100%",
                    padding: "0px",
                    height: {
                      sm:"100%",
                      md:"100%",
                    },
                    border:"1px solid #AAAAAA"
                  }}
                  square={true}
                  elevation={4}
                >
                        <MapComponent
                          locations={this.state.searchResults ? this.state.searchResults : []}
                          favorites={this.props.favorites}
                          markAsFavorite={this.props.markAsFavorite}
                          displayListingReport={this.displayListingReport}
                          gotoHome={this.state.gotoHome}
                          theme={this.props.theme}
                          setMapExtents={this.setMapExtents}
                          apiKey="AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8"
                        />
                </Paper>
              </Grid>

              <Grid
                item
                
                sx={{
                  
                  padding: "0px",
                  height: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                  },
                  
                  fontSize: "16px",
                  border: "0px solid red",
                  background: "#FFFFFF",
                }}
                xs={12}
                sm={12}
                md={5}
                lg={this.state.appSize === "small" ? 12-this.state.small.mapWidth:12-this.state.large.mapWidth}
                xl={this.state.appSize === "small" ? 12-this.state.small.mapWidth:12-this.state.large.mapWidth}
              >
                <Grid
                  className="listings-ref"
                  container
                  
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    background: "#FFFFFF",
                    height: "100%",
                    
                    border: "0px solid #AAAAAA",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {this.displayTiles()}
                  {this.getContactTile()}
                  {this.getListingApptTile()}
                  {this.getJoinTheTeamTile()}
                  {this.getMyServicesTile()}
                  {this.state.searchResults.length > 0 ? this.getIdxTile() : null}
                  {this.getFooter()}
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
          <ListingReport
            screenSize ={this.props.screenSize}
            parentPage="Search"
            listing={this.getSelectedListing()}
            showListingReport={this.state.showListingReport}
            toggleListingReport={this.toggleListingReport}
            showRequestShowing={this.showRequestShowing}
            getFinanceDownload={this.props.getFinanceDownload}
            getHouseHuntingDownload={this.props.getHouseHuntingDownload}
            requestShowing={this.props.requestShowing}
            showContact={this.props.showContact}
            favorites={this.props.favorites}
            markAsFavorite={this.props.markAsFavorite}
            displayRequestShowing={this.props.displayRequestShowing}
            getPhotoReport={this.props.getPhotoReport}
            theme={this.props.theme}
          />
          
          <RequestShowing
            screenSize ={this.props.screenSize}
            parentPage="Search"
            listingRequest={this.state.listingRequest}
            showRequestShowing={this.state.showRequestShowing}
            toggleRequestShowing={this.toggleRequestShowing}
            requestShowing={this.props.requestShowing}
            showContact={this.showContact}
            theme={this.props.theme}
          />


          <Contact
            showContact={this.state.showContact}
            toggleContact={this.toggleContact}
            theme={this.props.theme}
          />

        </Grid>
      </Drawer>

      
      )
    }
}

export default SearchListings;
